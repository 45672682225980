import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Servicos from "../components/servicos"
import SEO from "../components/seo"
import "../components/applify/sass/style.scss"
import "./css/index.scss"

const ServicosPage = () => (
  <Layout>
    <SEO title="Serviços" description="Conheça os principais serviços que a System Code oferece." keywords={[`ecommerce`, `loja virtual`, `web`, `aplicativos`, `infraestrutura`, `consultoria`, `softwares`]} />
    <div className="main" role="main">
      <div className="ui-hero hero-lg ui-gradient-sc ui-waves hero-svg-layer-1 sp-horizontal sp-has-buttons">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" data-vertical_center="true" data-vertical_offset="16">
              <h1 className="animate fade-in-up-big" data-show="fade-in-up-big" data-delay="100">
                Nossos Serviços
              </h1>
              <p className="text-white">Conheça os principais serviços que a <strong>System Code</strong> oferece.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container ui-icon-blocks ui-blocks-h icons-lg">
          <Servicos />
        </div>
      </div>
    </div>

  </Layout>
)

export default ServicosPage
