import React from 'react'
import Link from 'gatsby-link'

const Servicos = () => (
  <div>
    <div className="row">
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-credit-card text-green-sc"></div>
        <h4>Web</h4>
        <p>
          Criação de sites do zero seja ele estático ou dinâmico.
        </p>
        <Link to="web" className="btn-link btn-arrow">Saiba Mais</Link>
      </div>
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-credit-card text-green-sc"></div>
        <h4>E-commerce</h4>
        <p>
          Tenha sua loja virtual na plataforma Magento 2, a mais robusta e mais segura do mercado.
        </p>
        <Link to="ecommerce" className="btn-link btn-arrow">Saiba Mais</Link>
      </div>
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-screen-smartphone text-green-sc"></div>
        <h4>Aplicativos</h4>
        <p>
          Desenvolvemos aplicativos sob medida para Android e iOS.
        </p>
        <Link to="aplicativos" className="btn-link btn-arrow">Saiba Mais</Link>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-cloud-upload text-green-sc"></div>
        <h4>Infraestrutura</h4>
        <p>
          Trabalhamos desde servidores simples a escaláveis na nuvem.
        </p>
        <Link to="contato" className="btn-link btn-arrow">Saíba Mais</Link>
      </div>
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-graph text-green-sc"></div>
        <h4>Consultoria</h4>
        <p>
          Oferecemos nossa expertise para sua empresa inovar.
        </p>
        <Link to="contato" className="btn-link btn-arrow">Saíba Mais</Link>
      </div>
      <div className="col-sm-4 ui-icon-block">
        <div className="icon icon-lg icon-circle icon-screen-desktop text-green-sc"></div>
        <h4>Aplicações</h4>
        <p>
          Desenvolvimento de soluções para desktop ou web.
        </p>
        <Link to="contato" className="btn-link btn-arrow">Saíba Mais</Link>
      </div>
    </div>
  </div>

);

export default Servicos
